<div class="content-experience content-experience--catalogue">
    <div class="content-experience__body">
        <div class="box box-experience">
            <div class="box-experience__title mb-3 flex justify-between">
                <h3 class="title-table">
                    {{ "organize.certifacates.nav.certificate" | translate }}
                </h3>
                <div class="flex flex-col">
                    <!--
                                <msc-button
                                class="msc-button--primary ml-auto mb-3"
                                icon="icon-plus"
                                palette="secondary"
                                (click)="openModalAdd()"
                            >
                                {{ "words.add" | translate }}
                            </msc-button>-->

                    <div class="flex justify-content-end mr-3">
                        <div class="flex gap-4 justify-content-end align-center">
                            <msc-button class="msc-button--primary" icon="icon-trash" palette="secondary"
                                (click)="clearFilters()" [tooltip]="'words.reset-filters' | translate"
                                *ngIf="currentSelectedFilter != undefined">
                            </msc-button>
                            <div class="flex align-center w-50 pl-6 btn-filter">
                                <p>{{ "words.filters" | translate }}</p>
                                <msc-common-select class="text-sm w-full" [data]="filters">
                                    <ng-template #buttonTemplate let-data="data">
                                        <button class="select-main">
                                            <ng-container>
                                                <span>{{
                                                    currentSelectedFilter?.key
                                                    }}</span>
                                            </ng-container>
                                        </button>
                                    </ng-template>
                                    <ng-template #optionTemplate let-datum="datum">
                                        <button class="select-option" (click)="
                                                onSelectType(datum, 'filter')
                                            ">
                                            <span>{{ datum.key }}</span>
                                        </button>
                                    </ng-template>
                                </msc-common-select>
                            </div>
                            <div class="scale-50">
                                <msc-common-spinner *ngIf="isLoadingFilter"></msc-common-spinner>
                            </div>
                            <div class="w-52 pl-6" *ngIf="
                                    currentSelectedFilter != undefined &&
                                    !isLoadingFilter &&
                                    currentSelectedFilter?.value != 'email'
                                ">
                                <msc-common-select class="text-sm w-full" [data]="filteredFilters">
                                    <ng-template #buttonTemplate let-data="data">
                                        <button class="select-main">
                                            <ng-container>
                                                <span>{{
                                                    currentSelectedFilteredFilters?.title
                                                    | translate
                                                    }}</span>
                                            </ng-container>
                                        </button>
                                    </ng-template>
                                    <ng-template #optionTemplate let-datum="datum">
                                        <button class="select-option" (click)="
                                                onSelectType(
                                                    datum,
                                                    'filterSelect'
                                                )
                                            ">
                                            <span>{{
                                                datum.title | translate
                                                }}</span>
                                        </button>
                                    </ng-template>
                                </msc-common-select>
                            </div>

                            <msc-input [placeholder]="'words.email' | translate" class="w-2/4 pr-3"
                                [(ngModel)]="inputFilter" *ngIf="
                                    currentSelectedFilter?.value == 'email' &&
                                    !isLoadingFilter
                                "></msc-input>

                            <msc-button class="msc-button--primary ml-auto" icon="icon-search" palette="secondary"
                                (click)="filter()" *ngIf="
                                    currentSelectedFilteredFilters !=
                                        undefined || inputFilter != ''
                                ">
                                {{ "placeholder.search" | translate }}
                            </msc-button>

                            <msc-button class="mr-3 btn-refresh" icon="icon-refresh" palette="secondary"
                                (click)="clearFilters()" [tooltip]="'words.refresh' | translate" [disabled]="isLoading">
                            </msc-button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- tabla -->
            <div class="table-container overflow-x-auto bg-white border border-gray-50 shadow-sm">
                <table>
                    <thead class="box-experience__title sticky-top" style="z-index: 10">
                        <tr>
                            <th>
                                <span>{{ "words.name" | translate }}</span>
                            </th>
                            <th>
                                <span>{{ "words.email" | translate }}</span>
                            </th>
                            <th>
                                <span>{{
                                    "words.experience-name" | translate
                                    }}</span>
                            </th>
                            <th>
                                <span>{{
                                    "words.course-name" | translate
                                    }}</span>
                            </th>
                            <th>
                                <span>{{
                                    "words.certificate-link" | translate
                                    }}</span>
                            </th>
                            <th>
                                <span>{{
                                    "words.certificate-download" | translate
                                    }}</span>
                            </th>
                            <th>
                                <span>{{
                                    "words.certificate-status" | translate
                                    }}</span>
                            </th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <msc-common-spinner *ngIf="isLoading"></msc-common-spinner>
                    <tbody *ngIf="!isLoading">
                        <ng-container *ngFor="
                                let row of emitedCertificates;
                                let i = index
                            ">
                            <tr>
                                <td>
                                    <span class="clamp-2" [tooltip]="row.name">{{ row.name | titlecase }}
                                    </span>
                                </td>
                                <td>
                                    <span class="clamp-2" [tooltip]="row.email">{{ row.email | lowercase }}</span>
                                </td>
                                <td>
                                    <span class="clamp-2" [tooltip]="row.experience">{{ row.experience | titlecase
                                        }}</span>
                                </td>
                                <td>
                                    <span class="clamp-2" [tooltip]="row.course">{{ row.course | titlecase }}</span>
                                </td>

                                <td>
                                    <ng-container *ngIf="
                                            row.status != 'words.status-revoked'
                                        ">
                                        <i class="icon icon-eye mr-5 cursor-pointer" [tooltip]="row.link"></i>
                                        <i class="icon icon-copy cursor-pointer" (click)="copyLink(row.link)" [tooltip]="
                                                'words.copy-clipboard'
                                                    | translate
                                            "></i>
                                    </ng-container>
                                </td>

                                <td>
                                    <i class="icon icon-download-1 mr-5 cursor-pointer m-0 d-flex align-items-center justify-content-center align-content-cente"
                                        [tooltip]="'words.download' | translate" *ngIf="
                                            row.status != 'words.status-revoked'
                                        " (click)="downloadCertificate(row)"></i>
                                </td>
                                <td>
                                    <div *ngIf="
                                            row.status == 'words.status-pending'
                                        " class="w-24 p-1 rounded-lg min-h-9 pending text-center">
                                        <span>{{
                                            row.status | translate
                                            }}</span>
                                    </div>
                                    <div *ngIf="
                                            row.status == 'words.status-sended'
                                        " class="w-24 p-1 rounded-lg min-h-9 sended text-center">
                                        <span>{{
                                            row.status | translate
                                            }}</span>
                                    </div>
                                    <div *ngIf="
                                            row.status == 'words.status-failed'
                                        " class="w-24 p-1 rounded-lg min-h-9 failed text-center">
                                        <span>{{
                                            row.status | translate
                                            }}</span>
                                    </div>
                                    <div *ngIf="
                                            row.status == 'words.status-revoked'
                                        " class="w-24 p-1 rounded-lg min-h-9 revoked text-center">
                                        <span>{{
                                            row.status | translate
                                            }}</span>
                                    </div>
                                    <div *ngIf="
                                            row.status == 'words.status-emitted'
                                        " class="w-24 p-1 rounded-lg min-h-9 emitted text-center">
                                        <span>{{
                                            row.status | translate
                                            }}</span>
                                    </div>
                                </td>

                                <td>
                                    <div class="td-buttons">
                                        <msc-button *ngIf="
                                                row.status ==
                                                'words.status-emitted'
                                            " class="btn-opt-1 z-0 max-w-6" palette="secondary"
                                            icon="icon icon-file-remove" (click)="openModalDelete(row)" [tooltip]="
                                                'words.certificate-revoke'
                                                    | translate
                                            ">
                                        </msc-button>

                                        <msc-button *ngIf="
                                                row.status ==
                                                'words.status-emitted'
                                            " class="btn-opt-1 z-0" icon="icon icon-email" palette="primary"
                                            (click)="sendCertificate(row.id)" [tooltip]="
                                                'words.send-certificate-mail'
                                                    | translate
                                            ">
                                        </msc-button>
                                    </div>
                                </td>
                                <td>
                                    <label for="collapse-toggle" class="btn-collapse-arrow">
                                        <i class="icon icon-arrow-ios-down"></i>
                                      </label>
                                </td>
                            </tr>

                            <!-- Nueva fila para el collapse -->
                            <tr>
                                <td colspan="9" class="m-0 p-0 border-0 pb-4">
                                    <input type="checkbox" id="collapse-toggle" class="collapse-toggle" hidden />
                                    <div class="collapse my-2" id="collapseExample">
                                        <div class="card-body pb-4">
                                            <span class="pb-1">{{
                                                "words.dynamic-data-information"
                                                | translate
                                                }}</span>
                                            <br>
                                            <span *ngFor="
                                                    let fields of row.dynamicData
                                                " style="display: block">
                                                {{ fields.field }} :
                                                {{ fields.value }}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <!--pagination-->
            <nav aria-label="Page navigation my-4" [ngClass]="{ 'opacity-0 pointer-events-none': isLoading }"
                class="flex justify-center nav-pg">
                <ul class="flex items-center -space-x-px h-8 text-sm pt-4 mt-1">
                    <li class="paginationItemRounded" [tooltip]="'word.to.first' | translate"
                        [class.disabled]="currentPage === 1">
                        <a class="rounded-start  border-gray-300 text-gray-500 paginationItem flex items-center justify-center px-3 h-8 hover:bg-gray-100 hover:text-gray-700 leading-tight border"
                            [class.cursor-not-allowed]="currentPage === 1" (click)="currentPage > 1 && setPaginator(1)" style="border-radius: 8px 0px 0px 8px;">
                            <div class="icon-arrow-group" >
                                <i class="icon icon-arrow-ios-left-1"></i>
                                <i class="icon icon-arrowhead-left"></i>
                            </div>
                        </a>
                    </li>

                    <li *ngIf="paginationItems[0] !== 1">
                        <span></span>
                    </li>
                    <!-- Botón "Anterior" -->
                    <li [tooltip]="'word.previous.to.ten' | translate" (click)="
                            totalPages > 10 && changePageAdvance10('previous')
                        " [class.disabled]="currentPage <= 10" *ngIf="totalPages > 10">
                        <a class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            [class.cursor-not-allowed]="currentPage <= 10">
                            <i class="icon icon-arrowhead-left"></i>
                        </a>
                    </li>

                    <li [tooltip]="'word.to.previous' | translate" (click)="totalPages > 10 && changePage('previous')"
                        [class.disabled]="currentPage <= 10" *ngIf="totalPages > 10">
                        <a class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
                            [class.cursor-not-allowed]="currentPage === 1">
                            <span class="sr-only">Previous</span>
                            <i class="icon-arrow-ios-left-1"></i>
                        </a>
                    </li>

                    <!-- Páginas -->
                    <li *ngFor="let item of paginationItems">
                        <a class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 cursor-not-allowed"
                            [ngClass]="{
                                'hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white':
                                    currentPage !== item,
                                'z-10 text-blue-600 border-blue-300 bg-blue-50':
                                    currentPage === item
                            }" (click)="setPaginator(item)">
                            {{ item }}
                        </a>
                    </li>

                    <!-- Botón "Siguiente" -->
                    <li [tooltip]="'word.to.next' | translate" [class.disabled]="currentPage === totalPages"
                        (click)="currentPage < totalPages && changePage('next')">
                        <a class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
                            [class.cursor-not-allowed]="
                                currentPage === totalPages
                            ">
                            <span class="sr-only">Next</span>
                            <i class="icon-arrow-ios-right-1"></i>
                        </a>
                    </li>

                    <li [tooltip]="'word.next.to.ten' | translate" [class.disabled]="currentPage === totalPages"
                        (click)="totalPages > 10 && changePageAdvance10('next')">
                        <a class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
                            [class.cursor-not-allowed]="
                                currentPage === totalPages
                            ">
                            <i class="icon icon-arrowhead-right"></i>
                        </a>
                    </li>

                    <li class="paginationItemRounded" [tooltip]="'word.to.last' | translate"
                        [class.disabled]="currentPage === totalPages">
                        <a style="border-radius: 0px 8px 8px 0px;" class=" border-gray-300 rounded-end text-gray-500  roundeyztyuuyd-end paginationItem flex items-center justify-center px-3 h-8 hover:bg-gray-100 hover:text-gray-700 leading-tight border current-{{
                                currentPage
                            }} toral-{{ totalPages }}" [class.disabled]="totalPages < 10" [class.cursor-not-allowed]="
                                currentPage === totalPages
                            " (click)="
                                currentPage !== totalPages && goToLastPage()
                            ">
                            <div class="icon-arrow-group">
                                <i class="icon icon-arrow-ios-right-1"></i>
                                <i class="icon icon-arrowhead-right"></i>
                            </div>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<msc-common-modal size="20vw" #modalDelete [title]="'words.certificate.revoke-title' | translate">
    <ng-template>
        <span>{{ "words.certificate.revoke-text" | translate }}</span>

        <div class="flex items-center mt-3 space-x-5 justify-start">
            <msc-button class="msc-button--primary mr-3" palette="primary" (click)="revokeCerficate()">
                {{ "words.certificate-revoke" | translate }}
            </msc-button>

            <msc-button palette="primary" (click)="modalDelete.onClose()">
                {{ "common.cancel" | translate }}
            </msc-button>
        </div>
    </ng-template>
</msc-common-modal>
